import React, { useEffect, useState } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from '@syncfusion/ej2-react-grids';

import axios from 'axios';
import {  renewlsGrid } from '../data/dummy';
import { Button, Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

function Renewls() {
  const toolbarOptions = ['Search'];
  const { currentColor } = useStateContext();

  const editing = { allowDeleting: true, allowEditing: true };
  const [myResellerData, setMyResellerData] = useState([]);

  const resellerData = async () => {
    try {
      const res = await axios.get('https://yashsabal.xyz/api/getrenewls', {
        headers: {
          token:
            'U2FsdGVkX19SUs6h4EaSu9e00YuSWktyMPtdiadaL3nZDJHBwYeim/c8o8NQ8tA/QVLPfSAEv7ZdRqxMadzJBJPXF25PMKrvJcxjF1dc40eZfodoJOuRSvymwWg1D7wKk89LYr0sXmomNyRtIJ0x/nleS4/FfCj4mmmx6P/S++2cJ4Oqwf59USkAfIUP2PtgpRaC8yRqmMnzAgIxcMntc7VakVsXREY51jR/HUgg/jEWIyr6qEvhzrSvb648AAR6COVpuQ57m3WJUqgMvT7y0Cx2EovjDLooW8BbYaOON5UhhZO179CVU1cvImtLri50bJVl9S02M209CxFAbWGme96IcnCYMZPr18GcEmxugQQ=',
        },
      });
      setMyResellerData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    resellerData();
  }, []);
  console.log(myResellerData);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Renewls" />
      <div style={{ marginBottom: '24px' }}>
        <Button
          color="white"
          bgColor={currentColor}
          text="Expires Today"
          borderRadius="10px"
        />
      </div>
      <GridComponent
        dataSource={myResellerData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {renewlsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent>
    </div>
  );
}
export default Renewls;
