import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import {  inquiriesGrid } from "../data/dummy";
import { Header } from "../components";
import axios from "axios";

const Inquiries = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Delete"];
  const editing = { allowDeleting: true, allowEditing: true };
  const [myUserData, setMyUserData] = useState([]);

  const userData = async () => {
    try {
      const res = await axios.get("https://yashsabal.xyz/api/getallusers", {
        headers: {
          token:
            "U2FsdGVkX19SUs6h4EaSu9e00YuSWktyMPtdiadaL3nZDJHBwYeim/c8o8NQ8tA/QVLPfSAEv7ZdRqxMadzJBJPXF25PMKrvJcxjF1dc40eZfodoJOuRSvymwWg1D7wKk89LYr0sXmomNyRtIJ0x/nleS4/FfCj4mmmx6P/S++2cJ4Oqwf59USkAfIUP2PtgpRaC8yRqmMnzAgIxcMntc7VakVsXREY51jR/HUgg/jEWIyr6qEvhzrSvb648AAR6COVpuQ57m3WJUqgMvT7y0Cx2EovjDLooW8BbYaOON5UhhZO179CVU1cvImtLri50bJVl9S02M209CxFAbWGme96IcnCYMZPr18GcEmxugQQ=",
        },
      });
      setMyUserData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    userData();
  }, []);
  console.log(myUserData);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Inquiries" />
      <GridComponent
        dataSource={myUserData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {inquiriesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Inquiries;
