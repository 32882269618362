// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";

import { paymentsGrid } from "../data/dummy";
import { Button, Header } from "../components";
import axios from "axios";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

const payments = () => {
  const toolbarOptions = ["Search"];
  // eslint-disable-next-line
  const { currentColor } = useStateContext();
  // eslint-disable-next-line
  const editing = { allowDeleting: true, allowEditing: true };
  // eslint-disable-next-line
  const [myResellerData, setMyResellerData] = useState([]);
  // eslint-disable-next-line
  const resellerData = async () => {
    try {
      const res = await axios.get("https://yashsabal.xyz/api/getpayments", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      setMyResellerData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    resellerData();
    // eslint-disable-next-line
  }, []);
  console.log(myResellerData);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Payments And Purchases" />

      <div className="mt-10" style={{ marginBottom: "24px" }}>
        <Link to="/addreseller">
          <Button
            color="white"
            bgColor={currentColor}
            text="Add A payment"
            borderRadius="10px"
          />
        </Link>
      </div>
      <GridComponent
        dataSource={myResellerData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {paymentsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent>
    </div>
  );
};
export default payments;
