import React from "react";
import QRCode from "react-qr-code";

const PayNow = () => {
  return (
    <div className="mt-24">
      <h1 style={{ fontSize: "48px", color: "white", marginLeft: "510px" }}>
        Pay Now
      </h1>
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <QRCode value="<https://www.example.com>" />
        </div>
      </div>
    </div>
  );
};
export default PayNow;
