import axios from 'axios';

import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { useState } from 'react';

function Branding() {
  const [companyName, setCompanyName] = useState('');
  const [companylogo, setCompanylogo] = useState('');

  const userData = {
    name: companyName,
    logo: companylogo,
  };

  const convertToBase64 = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setCompanylogo(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error:', error);
    };
  };

  const handleChange = async () => {
    try {
      const res = await axios.put(
        'https://yashsabal.xyz/api/updateBranding',
        userData,
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      );
      alert(res.data.success);
      setCompanyName('');
      setCompanylogo('');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Add Your Brand Details</Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="companyName"
            name="companyName"
            label="companyName"
            type="companyName"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          />

          <input
            type="file"
            accept="image/*"
            onChange={convertToBase64}
            placeholder="Choose your Brand Image"
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleChange}
          >
            Update Details
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <img src={companylogo} alt="" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Branding;
