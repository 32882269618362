import { LockOutlined } from '@mui/icons-material';
import axios from 'axios';

import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const userData = {
    email,
    password,
  };

  const handleLogin = async () => {
    if (email === '' || password === '') {
      alert('please enter valid credentials');
    } else {
      try {
        const res = await axios.post(
          'https://yashsabal.xyz/api/login',
          userData,
        );
        console.log(res.data);
        // eslint-disable-next-line
        window.localStorage.setItem('token', res.data.token);
        window.localStorage.setItem('role', res.data.role);
        // eslint-disable-next-line
        location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
          <LockOutlined />
        </Avatar>
        <Typography variant="h5">Login</Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <Link to="/">
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Link>
          <Grid container justifyContent="flex-end">
            <Grid item />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
