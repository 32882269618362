import axios from 'axios';

import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';

function AddUser() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [software, setSoftware] = useState('');

  const userData = {
    email,
    password,
    phone,
    name,
  };

  const handleAddUser = async () => {
    if (email === '' || password === '' || phone === '' || name === '') {
      alert('please enter valid credentials');
    } else {
      try {
        if (software === 'database') {
          userData.database = true;
        } else if (software === 'whatsapp') {
          userData.whatsapp = true;
        } else {
          alert('Please Atleast add one Software!');
        }
        const res = await axios.post(
          'https://yashsabal.xyz/api/adduser',
          userData,
          {
            headers: {
              token: window.localStorage.getItem('token'),
            },
          },
        );
        console.log(res.data);
        alert(res.data.success);
        setEmail('');
        setPassword('');
        setPhone('');
        setName('');
        setSoftware('');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Add New User</Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            name="phone"
            label="phone"
            type="phone"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            label="name"
            type="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Select
            margin="normal"
            required
            fullWidth
            id="software"
            value={software}
            name="software"
            label="software"
            type="software"
            onChange={(e) => {
              setSoftware(e.target.value);
            }}
          >
            <MenuItem value="database">Database</MenuItem>
            <MenuItem value="whatsapp">Whatsapp</MenuItem>
          </Select>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleAddUser}
          >
            Login
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default AddUser;
