import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";

import { employeesGrid } from "../data/dummy";
import { Header } from "../components";
import axios from "axios";

const Employees = () => {
  // const selectionsettings = { persistSelection: true };

  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };
  const [myResellerData, setMyResellerData] = useState([]);

  const resellerData = async () => {
    try {
      const res = await axios.get("https://yashsabal.xyz/api/getressellers", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      setMyResellerData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    resellerData();
  }, []);
  console.log(myResellerData[0]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Resellers" />

      <GridComponent
        dataSource={myResellerData}
        width="auto"
        allowPaging
        allowSorting
        selectionsettings
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {employeesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent>
    </div>
  );
};
export default Employees;
