import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import {  customersGrid } from "../data/dummy";
import { Header } from "../components";
import axios from "axios";

const Customers = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];
  const editing = { allowDeleting: true, allowEditing: true };
  const [myUserData, setMyUserData] = useState([]);

  const userData = async () => {
    try {
      const res = await axios.get("https://yashsabal.xyz/api/getallusers", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      setMyUserData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    userData();
  }, []);
  console.log(myUserData);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Users" />
      <GridComponent
        dataSource={myUserData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {customersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Customers;
