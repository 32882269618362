import React from "react";

import { Button, Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";

const Orders = () => {
  const { currentColor } = useStateContext();

  if (localStorage.getItem("role") === "admin") {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header title="Add Users / Resellers" />
        <div className="mt-10">
          <Link to="/adduser">
            <Button
              color="white"
              bgColor={currentColor}
              text="Add User"
              borderRadius="10px"
            />
          </Link>
        </div>
        <div className="mt-10">
          <Link to="/addreseller">
            <Button
              color="white"
              bgColor={currentColor}
              text="Add Reseller"
              borderRadius="10px"
            />
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Add Users / Resellers" />
      <div className="mt-10">
        <Link to="/adduser">
          <Button
            color="white"
            bgColor={currentColor}
            text="Add User"
            borderRadius="10px"
          />
        </Link>
      </div>
    </div>
  );
};
export default Orders;
