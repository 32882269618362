import axios from "axios";

import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
// import { Link } from "react-router-dom";
const AddResellers = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  let userData = {
    email: email,
    password: password,
    phone: phone,
    name: name,
  };

  const handleLogin = async () => {
    if (email === "" || password === "" || phone === "" || name === "") {
      alert("please enter valid credentials");
    } else {
      try {
        const res = await axios.post(
          "https://yashsabal.xyz/api/addressellers",
          userData,
          {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          }
        );
        console.log(res.data);
        alert(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Add New User</Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              name="phone"
              label="phone"
              type="phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              name="name"
              label="name"
              type="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Login
            </Button>

            <Grid container justifyContent={"flex-end"}>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AddResellers;
